.choroplet-map {
    width: 100%;
    height: 200px;
}

@media (min-width: 576px) {
    .choroplet-map {
        height: 240px;
    }
}

@media (min-width: 768px) {
    .choroplet-map {
        height: 300px;
    }
}

@media (min-width: 992px) {
    .choroplet-map {
        height: 340px;
    }
}

@media (min-width: 1200px) {
    .choroplet-map {
        height: 400px;
    }
}
