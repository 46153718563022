.mapContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

/**
 * Custom CSS from old file App.css 
 * Keep here as it is defaulted included 
 */

.main-hero {
  /*background-image: url(https://source.unsplash.com/11SgH7U6TmI);*/
  background-image: url(https://htmlstream.com/preview/front-v2.4.0/assets/img/1920x800/img2.jpg);
}

.link-disabled {
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  pointer-events: none;
}

.bg-footer {
  background-color: #444;
}

.bg-footer-bottom-banner {
  background-color: #444444;
}

.border-bottom-footer {
  border-bottom: 1px solid #585858 !important;
}

.footer-shape {
  top: -100px;
  right: 0;
  opacity: 0.05;
}

.border-top-dark {
  border-top: 1px solid #1e2022 !important;
}

.border-bottom-dark {
  border-bottom: 1px solid #1e2022 !important;
}

.flatpickr-wrapper {
  width: 100%;
}

/*  iPhone SVG  */

.iphone-x-2-0 {
  fill: #FFFFFF;
}

.iphone-x-2-1 {
  fill: none;
  stroke: #F7F7F7;
}

.iphone-x-2-2 {
  fill: none;
  stroke: #E7EAF3;
}

.iphone-x-2-3 {
  fill: #F7F7F7;
}

.macbook-0 {
  fill: #FFFFFF;
  stroke: #F7F7F7;
}

.missing.form-control::-webkit-input-placeholder {
  color: black;
}

.x-twitter:hover path{
  fill: #1a6aff;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}